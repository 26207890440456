(function($, ServiceBus, Topics) {
  if (!Topics || !Topics.queries) {
    return;
  }

  const PerlgemVtoHook = function() {
    return {
      beforeQuery: function(queryName, payload, next) {
        switch (queryName) {
          case Topics.queries.GET_USER_DETAILS: {
            return getPerlgemUser();
          }
          default: {
            return next(queryName, payload);
          }
        }
      },
    };
  };

  const getPerlgemUser = function() {
    const deferred = $.Deferred();

    if (ServiceBus.log) {
      ServiceBus.log({
        message: 'pg_'.concat(Topics.queries.GET_USER_DETAILS),
      });
    }

    $(document).trigger('perlgem.user.fetch', [deferred]);

    return deferred.promise();
  };

  ServiceBus.applyHook(PerlgemVtoHook);
})(jQuery, window.GlobalServiceBus || {}, window.ServiceBusTopics || {});
